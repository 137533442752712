import React from 'react';
import Layout from '../components/Layout';

const uno = () => {
    return (
        <Layout>
            <div id="main">
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title" style={{fontSize: 'x-large'}}>Blockchain
                            Development</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000', fontSize: 'large'}}>
                                Nowadays Blockchain has become most discussed topic around many businesses, especially
                                in the IT Field. This technology has made a new gateway for payments which is extremely
                                secure. A blockchain is an excellent form of Database storage system, which uses records
                                to store data or information. These records or blocks get copied automatically with the
                                mechanism of cryptography providing a more secure data storage platform. This means,
                                your data is stored securely in multiple areas, reducing the overall cost of data
                                storage. The blockchain is the technology which supports the cryptocurrencies and
                                Digital currencies.
                            </p>
                            <p style={{color: '#000', fontSize: 'large'}}>
                                So, Businesses having a huge amount of big data can hire Blockchain developers to create
                                new and more efficient business processes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default uno;
